import { CheckIcon, XIcon } from 'lucide-react';
import { cn } from '~/utils/cn';

interface ProgressPillProps {
  correct: number;
  incorrect: number;
  className?: string;
}

export function ProgressPill({
  correct,
  incorrect,
  className,
}: ProgressPillProps) {
  return (
    <span
      className={cn(
        'flex items-center bg-muted px-3 py-2 rounded-3xl gap-3',
        className,
      )}
    >
      <span className="flex items-center text-sm font-semibold gap-1 text-emerald-600">
        <CheckIcon size={16} />
        <span>{correct}</span>
      </span>
      <span className="flex items-center text-sm font-semibold gap-1 text-rose-600">
        <XIcon size={16} />
        <span>{incorrect}</span>
      </span>
    </span>
  );
}
